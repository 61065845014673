import React from "react"
import { Select } from "@chakra-ui/react"

import "./scroll.css"

const styles = {
  enabled: {
    textAlign: "center",
    color: "#595C5F",
    fontSize: "16px",
    fontWeight: 400
  },
  disabled: {
    textAlign: "center",
    color: "#D4D5D7",
    fontSize: "16px",
    fontWeight: 400
  },
}

const AlphabetDropdown = ({
  onChange,
  availableAlphabets = [],
  color = "#595C5F",
}) => {
  const [selectedValue, setSelectedValue] = React.useState(
    availableAlphabets?.[0] || ""
  )

  const alphabet = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  )

  const handleValueChange = val => {
    setSelectedValue(val)
    onChange(val)
  }

  return (
    <Select
      w={"44px"}
      value={selectedValue}
      variant="unstyled"
      onChange={e => handleValueChange(e.target.value)}
      className="scrollbar-small"
      color={color}
      fontSize="14px"
      fontWeight={700}
    >
      {alphabet.map(letter => {
        const isDisabled = !availableAlphabets.includes(letter)
        return (
          <option
            key={letter}
            value={letter}
            style={isDisabled ? styles.disabled : styles.enabled}
            disabled={isDisabled}
          >
            {letter}
          </option>
        )
      })}
    </Select>
  )
}

export default AlphabetDropdown

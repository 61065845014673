import React from "react"
import { MdOutlineWorkspacePremium } from "react-icons/md"
import {
  Flex,
  Text,
  Icon,
  Link,
  GridItem,
  Divider,
  Box,
} from "@chakra-ui/react"

import isEmpty from "lodash/isEmpty"
import capitalize from "lodash/capitalize"

const styles = {
  name: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "10px",
    px: "15px",
    display: { base: "none", md: "table-cell", lg: "table-cell" },
  },
  data: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "10px",
    px: "15px",
    display: { base: "none", md: "table-cell", lg: "table-cell" },
  },
  "grouped-data": {
    py: "12px",
    px: "15px",
    display: { base: "table-cell", md: "none", lg: "none" },
  },
  "mobile-name": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  link: {
    color: "#4A90E2",
    "&:hover,&:focus": {
      textDecoration: "underline",
    },
  },
}
const SurgeonList = ({
  data,
  group,
  surgeonLinks,
  hasSubspecialties = false,
}) => {
  return data.map((surgeonItem, index) => {
    const name = `${capitalize(surgeonItem.last_name)}${
      isEmpty(surgeonItem.first_name)
        ? ""
        : ", " + capitalize(surgeonItem.first_name)
    }`
    // const organization = `${capitalizeFirstLetterOfEachWord(
    //   surgeonItem.hospital
    // )}`
    const location = surgeonItem.state
    const subspecialty = surgeonItem.specialty

    const link = surgeonLinks.find(
      item => item?.npi?.toString() === surgeonItem?.npi?.toString()
    )

    return (
      <React.Fragment key={`${index}-${group}`}>
        {index === 0 ? <Box id={group} /> : <Box />}
        <GridItem colSpan={4} sx={styles["grouped-data"]}>
          <Flex justifyContent="space-between">
            <Text sx={styles["mobile-name"]}>
              {link ? (
                <Link isExternal href={link.href} sx={styles.link}>
                  {name}
                </Link>
              ) : (
                name
              )}
            </Text>

            <Flex alignItems="center">
              <Icon
                as={MdOutlineWorkspacePremium}
                w="16px"
                h="16px"
                lineHeight="16px"
              />
              <Text sx={styles.text}>{subspecialty || " -"}</Text>
            </Flex>
          </Flex>
          {/* <Text sx={styles.text}>{organization}</Text> */}
          <Text sx={styles.text}>{location}</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Divider />
        </GridItem>
        <GridItem sx={styles.name}>
          {link ? (
            <Link isExternal href={link.href} sx={styles.link}>
              {name}
            </Link>
          ) : (
            name
          )}
        </GridItem>
        {/* <GridItem sx={styles.data}>{organization}</GridItem> */}
        <GridItem sx={styles.data}>{location}</GridItem>
        {hasSubspecialties && (
          <GridItem sx={styles.data}>{subspecialty}</GridItem>
        )}
      </React.Fragment>
    )
  })
}

export default SurgeonList

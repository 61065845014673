import React from "react"
import {
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react"

import PromoteAllStarsContent from "./promote-allstars-content"

import { RANKING_TAB } from "../../constants/constants"

const styles = {
  root: {
    width: { base: "100%", md: "auto", lg: "auto" },
    pt: { base: "10px", md: "0", lg: "0" },
  },
  "promote-button": {
    flex: "1",
    minWidth: "210px",
    padding: "8px 16px",
    bg: "#ECC94B",
    borderRadius: "4px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    "&:hover,&:active,&:focus": {
      bg: "#ECC94B",
      outline: "none",
      border: "none",
    },
  },
  "contact-button": {
    flex: "1",
    minWidth: "0",
    ml: "14px",
    py: "8px",
    px: { base: "12px", md: "16px", lg: "16px" },
    bg: "#129459",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const ButtonSection = ({ navigateToContactUs = () => {}, activeTab }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex sx={styles.root}>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody p="0px">
            <PromoteAllStarsContent
              entity={
                activeTab === RANKING_TAB.HOSPITAL ? "hospitals" : "surgeons"
              }
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Button sx={styles["promote-button"]} onClick={onOpen}>
        Promote Your All-Star Status
      </Button>
      <Button sx={styles["contact-button"]} onClick={navigateToContactUs}>
        Contact Us
      </Button>
    </Flex>
  )
}

export default ButtonSection

import React from "react"

import {
  Flex,
  Icon,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  IconButton,
  Link,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import { MdOutlineLock } from "react-icons/md"

const SECRET = "AGHresearch2023"

const styles = {
  root: {
    justifyContent: "center",
    alignItems: "center",
  },

  "inner-box": {
    maxWidth: "501px",
    my: { base: "55px", md: "55px", lg: "124px" },
    padding: { base: "30px", md: "0", lg: "0" },
    alignItems: "center",
    flexDirection: "column",
  },

  "lock-icon-wrapper": {
    w: "54px",
    h: "54px",
    borderRadius: "50%",
    bg: "#ECEFF7",
    alignItems: "center",
    justifyContent: "center",
  },

  heading: {
    mt: "12px",
    fontSize: "24px",
    textAlign: "center",
    fontWeight: 700,
    color: "#2D3748",
    lineHeight: "32px",
  },

  message: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0em",
    color: "#2D3748",
  },

  "password-section": {
    mt: "35px",
    justifyContent: "center",
  },

  "password-field": {
    size: "md",
    display: "block",
    maxW: "266px",
  },

  "lock-icon": {
    color: "#2D3748",
    w: "24px",
    h: "24px",
  },

  "eye-icon": {
    color: "#595C5F",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  button: {
    ml: "14px",
    fontSize: "14px",
    bg: "#129459",
    borderRadius: "4px",
    color: "#F1F1F1",
    px: "24px",
    lineHeight: "24px",
    outline: "none",

    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
    _disabled: {
      backgroundColor: "#D9D9D9",
      cursor: "not-allowed",
      color: "white",
    },
  },
}

const PasswordForm = ({ setShowPasswordForm }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [password, setPassword] = React.useState("")
  const [isError, setIsError] = React.useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true)

  const handleClick = () => setShowPassword(!showPassword)

  const handleInputChange = e => {
    const value = e.target.value

    setPassword(value)
    if (value.length === 0) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
    setIsError(false)
  }

  const handleButtonClick = () => {
    if (password === SECRET) {
      setShowPasswordForm(false)
      return
    }
    setIsError(true)
  }

  return (
    <Flex sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["lock-icon-wrapper"]}>
          <Icon as={MdOutlineLock} sx={styles["lock-icon"]} />
        </Flex>
        <Text sx={styles.heading}>
          Enter the password to continue
        </Text>
        <Text mt="12px" sx={styles.message}>
          You must have received a password in email. Please enter it below.
        </Text>
        <form>
          <FormControl isInvalid={isError}>
            <Flex sx={styles["password-section"]}>
              <InputGroup sx={styles["password-field"]}>
                <Input
                  pr="32px"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  onChange={handleInputChange}
                  autoComplete="new-password"
                />
                <InputRightElement>
                  <IconButton
                    variant="unstyled"
                    style={styles["eye-icon"]}
                    size="lg"
                    onClick={handleClick}
                    aria-label={
                      showPassword ? "Hide password" : "Show password"
                    }
                    icon={
                      showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )
                    }
                  />
                </InputRightElement>
                <FormErrorMessage>
                  This is not the correct password
                </FormErrorMessage>
              </InputGroup>
              <Button
                sx={styles.button}
                onClick={handleButtonClick}
                isDisabled={isButtonDisabled}
              >
                View List
              </Button>
            </Flex>
          </FormControl>
        </form>
        <Text mt="45px" sx={styles.message}>
          If you haven’t received the password, please{" "}
          <Link color="#3399FF" href="/contact-us" isExternal>
            contact us
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

export default PasswordForm

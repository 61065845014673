import React from "react"
import { Flex, Text, Box, Link, GridItem, Divider } from "@chakra-ui/react"
import { HOSPITAL_TYPE } from "../../constants/constants"

const styles = {
  name: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "10px",
    px: "15px",
    display: { base: "none", md: "table-cell", lg: "table-cell" },
  },
  data: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "10px",
    px: "15px",
    display: { base: "none", md: "table-cell", lg: "table-cell" },
  },
  "grouped-data": {
    py: "12px",
    px: "15px",
    display: { base: "table-cell", md: "none", lg: "none" },
  },
  "mobile-name": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  "dot-seperator": {
    w: "3px",
    h: "3px",
    borderRadius: "1px",
    bg: "#2D3748",
    mx: "7px",
  },
  link: {
    color: "#4A90E2",
    "&:hover,&:focus": {
      textDecoration: "underline",
    },
  },
}

const HospitalList = ({ data, group, hospitalLinks }) => {
  return data.map((hospitalItem, index) => {
    const name = hospitalItem?.hospital
    const location = `${hospitalItem.city}, ${hospitalItem.state}`
    const type =
      hospitalItem.teaching === HOSPITAL_TYPE.TEACHING
        ? "Teaching Hospital"
        : "Community Hospital"

    const link = hospitalLinks.find(
      item => item?.provider?.toString() === hospitalItem?.provider?.toString()
    )

    return (
      <React.Fragment key={`${index}-${group}`}>
        {index === 0 ? <Box id={group} /> : <Box />}
        <GridItem colSpan={3} sx={styles["grouped-data"]}>
          <Text sx={styles["mobile-name"]}>
            {link ? (
              <Link isExternal href={link.href} sx={styles.link}>
                {name}
              </Link>
            ) : (
              name
            )}
          </Text>
          <Flex alignItems="center">
            <Text sx={styles.text}>{location}</Text>
            <Box sx={styles["dot-seperator"]}></Box>
            <Text sx={styles.text}>{type}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <Divider />
        </GridItem>
        <GridItem sx={styles.name}>
          {link ? (
            <Link isExternal href={link.href} sx={styles.link}>
              {name}
            </Link>
          ) : (
            name
          )}
        </GridItem>
        <GridItem sx={styles.data}>{location}</GridItem>
        <GridItem sx={styles.data}>{type}</GridItem>
      </React.Fragment>
    )
  })
}

export default HospitalList

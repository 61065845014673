import React from "react"

import { graphql } from "gatsby"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/ranking/section-hero"
import SectionBody from "../components/ranking/section-body"

import BannerImage from "../images/ranking/ranking-hero.png"

const RankingPageTemplate = props => {
  const { site } = props.data

  const [showAllData, setShowAllData] = React.useState(false)

  const imageUrl = site.siteMetadata.siteUrl + BannerImage

  const name = props.pageContext?.page?.name

  return (
    <Layout
      location={props.location}
      title={`Best Surgeons & Hospitals For ${name} Research`}
      image={imageUrl}
      description={`Find the best surgeons and hospitals, based on their hospital surgeries and published ${name} research. Featuring the All-Stars in ${name}.`}
      keywords={`best ${name} surgeons, best ${name} hospitals, ${name} surgeons, top ${name} surgeons, ${name} hospital, list of ${name} surgeons`}
    >
      <SectionHero
        setShowAllData={setShowAllData}
        showAllData={showAllData}
        name={name}
        page={props.pageContext?.page}
        isOverallHospital={props.pageContext?.page?.isOverallHospital}
      />

      <SectionBody
        location={props.location}
        setShowAllData={setShowAllData}
        showAllData={showAllData}
        json={props.pageContext?.page?.json}
        hasSubspecialties={props.pageContext?.page?.hasSubspecialties}
        isOverallHospital={props.pageContext?.page?.isOverallHospital}
      />
    </Layout>
  )
}

export default RankingPageTemplate

export const query = graphql`
  query RankingsTemplateQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

import React from "react"
import {
  Box,
  Text,
  Flex,
  Link,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

import {
  ALL_STAR_MAIL_LINK,
  ALL_STAR_PAGES_URL,
} from "../../constants/constants"

const styles = {
  root: {
    color: "#2D3748",
    py: "65px",
    px: { base: "16px", md: "56px", lg: "56px" },
  },
  heading: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    color: "#2D3748",
  },
  "sub-heading": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  item: {
    flexDirection: { base: "column", md: "row", lg: "row" },
  },
}

const PromoteAllStarsContent = ({ entity = "surgeons" }) => {
  return (
    <Box p={4} sx={styles.root}>
      <Text sx={styles.heading}>Promote Your All-Star Status</Text>

      <Box mt="32px">
        <Text sx={styles.text} mt="4px">
          Congratulations on being named to the elite list of Healthcare
          Research All-Stars for 2024! This honor is a recognition of the
          quality and quantity of the research you authored and published in
          2021-2022. Your inclusion in the list indicates that you are among the
          top 5% of all {entity} publishing leading-edge healthcare research.
        </Text>
        <Text sx={styles.text} mt="12px">
          To help you maximize the value of this honor, we’re providing several
          assets for your use:
        </Text>
      </Box>

      <Flex mt="24px" sx={styles.item}>
        <Box>
          <Text sx={styles["sub-heading"]}>Badges</Text>
          <UnorderedList mt="4px">
            <ListItem sx={styles.text}>
              We’ve created a variety of badges for use on your website, social
              media posts and other electronic media. You can download your
              badge(s){" "}
              <Link
                color="#3399FF"
                isExternal
                href={"/" + ALL_STAR_PAGES_URL.BADGES}
              >
                here
              </Link>
              .
            </ListItem>
            <ListItem sx={styles.text}>
              You are pre-approved to use the appropriate badge(s) with one
              requirement: Badges posted in public media (websites, social
              media, etc.) should include a link to the Research All-Stars page:{" "}
              <Link
                color="#3399FF"
                isExternal
                href={"/" + ALL_STAR_PAGES_URL.HOME}
              >
                https://avantgardehealth.com/best-surgeons-and-hospitals-research-all-stars
              </Link>
              . When you do this, please let us know at{" "}
              <Link color="#3399FF" href={ALL_STAR_MAIL_LINK}>
                Research.All-Stars@avantgardehealth.com
              </Link>{" "}
              and we’ll update your listing with a link back to your website.
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>

      <Flex mt="24px" sx={styles.item}>
        <Box>
          <Text sx={styles["sub-heading"]}>Social Media Template</Text>
          <UnorderedList mt="4px">
            <ListItem sx={styles.text}>
              We’ve created a variety of social media templates for LinkedIn,
              Facebook and X (Twitter). You can use these to publicize your
              status as a Healthcare Research All-Star. You can download social
              media templates{" "}
              <Link
                color="#3399FF"
                isExternal
                href={"/" + ALL_STAR_PAGES_URL.SOCIAL_MEDIA_TEMPLATES}
              >
                here
              </Link>
              .
            </ListItem>
          </UnorderedList>
        </Box>
      </Flex>

      <Text sx={styles.text} mt="24px">
        If you have any questions or difficulty with your badge(s) or social
        media templates, please contact us at{" "}
        <Link color="#3399FF" href={ALL_STAR_MAIL_LINK}>
          Research.All-Stars@avantgardehealth.com
        </Link>
        . We’re happy to help!
      </Text>
    </Box>
  )
}

export default PromoteAllStarsContent

import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

const styles = {
  active: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    color: "#3399FF",
    cursor: "pointer",
    borderRadius: "2px",
    padding: "0 6px",
    "&:hover,&:active,&:focus": {
      background: "rgb(51 153 255 / 20%)",
    },
  },
  disabled: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    color: "#D4D5D7",
    borderRadius: "2px",
    padding: "0 6px",
  },
}

const AlphabetSelector = ({
  availableLetters = [],
  onLetterSelect = () => {},
}) => {
  const alphabet = Array.from({ length: 26 }, (_, index) =>
    String.fromCharCode(65 + index)
  )

  return (
    <Flex direction="row" align="center" justify="center">
      {alphabet.map(letter => {
        const isActive = availableLetters.includes(letter)
        return (
          <Box key={letter}>
            {isActive ? (
              <Text sx={styles.active} onClick={() => onLetterSelect(letter)}>
                {letter}
              </Text>
            ) : (
              <Text sx={styles.disabled}>{letter}</Text>
            )}
          </Box>
        )
      })}
    </Flex>
  )
}

export default AlphabetSelector

import React, { useEffect, useState, useCallback } from "react"

import { StaticImage } from "gatsby-plugin-image"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { FaSquareXTwitter } from "react-icons/fa6"
import { FaFacebook, FaLinkedin } from "react-icons/fa"

import { Box, Flex, Heading, Link, Text, Icon } from "@chakra-ui/react"

import { INTERSECTION_OBSERVER_OPTION } from "../../constants/constants"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "427px" },
    width: "100%",
    px: "24px",
    bg: "#ECEFF7",
  },
  "inner-box": {
    pt: { base: "32px", md: "32px", lg: "0px" },
    margin: "0 auto",
    width: "100%",
    height: "100%",
    maxWidth: "1378px",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "520px" },
    flexDirection: "column",
  },
  "main-heading": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "14px",
    letterSpacing: "0em",
    color: "#129459",
    pt: "20px",
  },
  heading: {
    fontSize: { base: "32px", md: "40px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "40px", md: "48px", lg: "48px" },
    letterSpacing: "0em",
    pt: "8px",
    color: "#2D3748",
    textAlign: "left",
  },
  socials: {
    color: "#595C5F",
    mt: "24px",
    alignItems: "center",
    gap: "16px",
  },
  "social-icon": {
    width: "32px",
    height: "32px",
  },
  "page-links": {
    alignItems: "center",
    mt: "24px",
    flexWrap: "wrap",
  },
  link: {
    fontSize: "16px",
    color: "#129459",
    fontWeight: 700,
    lineHeight: "24px",
    marginRight: "16px",
    mb: "16px",
  },
  "dot-seperator": {
    marginRight: "16px",
    w: "9px",
    h: "9px",
    borderRadius: "50%",
    bg: "#D9D9D9",
    mb: "16px",
  },
  text: {
    mt: "24px",
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "#2D3748",
  },
  image: {
    pt: "7px",
    textAlign: "center",
    width: { base: "140px", md: "180px", lg: "224px" },
    ml: { base: "0", md: "0", lg: "140px" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
  "drawer-header": {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#2D3748",
    px: "0",
    pb: "0",
    pt: { base: "15px", md: "25px", lg: "25px" },
  },
  "modal-content": {
    px: { base: "20px", md: "58px", lg: "58px" },
    width: "762px",
    pb: { base: "36px", md: "72px", lg: "72px" },
    maxWidth: "762px",
  },
  "modal-text": {
    pt: "24px",
    fontSize: "16px",
    fontWeight: 400,
    color: "#2D3748",
    lineHeight: "24px",
    letterSpacing: "0em",
  },
}

const SectionHero = ({
  setShowAllData = () => {},
  showAllData = false,
  name = "",
  page,
  isOverallHospital = false,
}) => {
  const [url, setUrl] = useState("")

  const loader = React.useRef(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href)
    }
  }, [])

  const handleObserver = useCallback(
    entries => {
      const target = entries[0]

      if (!target.isIntersecting) {
        if (!showAllData) {
          setShowAllData(true)
        }
      }
    },
    [showAllData, setShowAllData]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      handleObserver,
      INTERSECTION_OBSERVER_OPTION
    )
    if (loader.current) observer.observe(loader.current)

    return () => {
      observer.disconnect()
    }
  }, [handleObserver])

  const currentYear = new Date().getFullYear()

  let lowercaseName = page?.keyword || name
  if (!["ENT", "GI"].includes(lowercaseName)) {
    lowercaseName = lowercaseName.toLowerCase()
  }

  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["icon-box"]}>
          <ChevronDownIcon sx={styles.icon} />
        </Flex>
        <Flex sx={styles["left-box"]}>
          <Heading as="h1" sx={styles["main-heading"]}>
            Healthcare Research All-Stars
          </Heading>
          <Heading sx={styles.heading} size="2xl" as="h2">
            {name} Research All&#x2011;Stars: {currentYear}
          </Heading>
          <Text sx={styles.text}>
            Meet the {!isOverallHospital ? "best surgeons and " : ""}hospitals,
            honored based on the quantity and quality of their published{" "}
            {lowercaseName} research.
          </Text>
          <Box ref={loader} />
          <Flex sx={styles["socials"]}>
            <Text>Share this research</Text>
            <Link
              isExternal
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
            >
              <Icon as={FaFacebook} sx={styles["social-icon"]} />
            </Link>
            <Link
              href={`https://www.linkedin.com/shareArticle?url=${url}`}
              isExternal
            >
              <Icon as={FaLinkedin} sx={styles["social-icon"]} />
            </Link>
            <Link href={`https://www.twitter.com/share?url=${url}`} isExternal>
              <Icon as={FaSquareXTwitter} sx={styles["social-icon"]} />
            </Link>
          </Flex>
        </Flex>

        <Box sx={styles.image}>
          <StaticImage
            src="../../images/allstar/Healthcare Research All-Stars 2024 - Logo-transparent.png"
            alt={`Healthcare Research All-Stars logo`}
            placeholder="blurred"
          />
        </Box>
      </Flex>
    </Box>
  )
}

export default SectionHero

import React, { useEffect, useCallback } from "react"

import sortBy from "lodash/sortBy"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import groupBy from "lodash/groupBy"

import { Box, Flex, useToast } from "@chakra-ui/react"

import PasswordForm from "./password-form"
import RankingTable from "./ranking-table"
import RankingFilter from "./ranking-filter"

import {
  RANKING_TAB,
  SHOW_PASSWORD_ON_ALL_START_PAGE,
} from "../../constants/constants"

const styles = {
  container: {
    mx: "auto",
    maxWidth: "1426px",
  },

  content: {
    m: "24px 20px 29px 0",
  },

  filter: {
    ml: "24px",
    w: "280px",
    flex: "0 0 auto",
    display: {
      base: "none",
      md: "none",
      lg: "block",
    },
  },

  table: {
    flex: "1 1 auto",
    ml: { base: "20px", md: "20px", lg: "50px" },
    width: "100%",
  },
}

const INITIAL_FILTERS = {
  hospital: {
    hospitalType: "All",
    specialty: "All",
    bucket: 1,
  },
  surgeon: {
    specialty: "All",
    bucket: 1,
  },
}

const SectionBody = ({
  location,
  setShowAllData = () => {},
  showAllData = false,
  json = "",
  hasSubspecialties = false,
  isOverallHospital = false,
}) => {
  const [showPasswordForm, setShowPasswordForm] = React.useState(
    SHOW_PASSWORD_ON_ALL_START_PAGE
  )

  const toast = useToast()

  const [hospitals, setHospitals] = React.useState([])
  const [filteredHospitals, setFilteredHospitals] = React.useState([])
  const [surgeons, setSurgeons] = React.useState([])
  const [filteredSurgeons, setFilteredSurgeons] = React.useState([])
  const [availableLetters, setAvailableLetters] = React.useState([])

  const [activeTab, setActiveTab] = React.useState(
    isOverallHospital ? RANKING_TAB.HOSPITAL : RANKING_TAB.SURGEON
  )

  const [activeFilters, setActiveFilters] = React.useState(INITIAL_FILTERS)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const queryParam = location ? searchParams.get("type") : null

    const isValidQueryParam =
      queryParam && (queryParam === "hospitals" || queryParam === "surgeons")

    setActiveTab(
      isOverallHospital
        ? RANKING_TAB.HOSPITAL
        : isValidQueryParam
        ? queryParam.slice(0, -1)
        : RANKING_TAB.SURGEON
    )
  }, [location, isOverallHospital])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [surgeonResponse, response] = await Promise.all([
          fetch(`/data/surgeon_ranking_${json}.json`),
          fetch(`/data/hospital_ranking_${json}.json`),
        ])

        const [surgeonData, data] = await Promise.all([
          isOverallHospital ? null : surgeonResponse.json(),
          response.json(),
        ])

        const sortedSurgeons = sortBy(surgeonData, ["last_name", "first_name"])
        setSurgeons(sortedSurgeons)

        const sortedHospitals = sortBy(data, "hospital")
        setHospitals(sortedHospitals)
      } catch (err) {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    }

    fetchData()
  }, [toast, json, isOverallHospital])

  const handleTabchange = tab => {
    setActiveTab(tab)
    setActiveFilters(INITIAL_FILTERS)
    window.history.replaceState({}, "", `?type=${tab}s`)
  }

  const handleFilterOptionChange = (key, value) => {
    updateFilter(activeTab, key, value)
  }

  const updateFilter = (filterType, key, value) => {
    setActiveFilters(prevFilters => {
      const next = {
        ...INITIAL_FILTERS,
        [filterType]: {
          ...(key !== "bucket" && {
            ...INITIAL_FILTERS[filterType],
            bucket: prevFilters[filterType]["bucket"],
          }),
          ...(key === "bucket" && {
            ...prevFilters[filterType],
            bucket: prevFilters[filterType]["bucket"],
          }),
          [key]: value,
        },
      }

      return next
    })
  }

  const applyFilters = useCallback(() => {
    let filterToApply = {}

    Object.entries(activeFilters[activeTab]).forEach(([key, value]) => {
      if (key === "hospitalType") {
        if (value === "All") {
          return
        } else if (value === "teaching") {
          filterToApply.teaching = 1.0
        } else {
          filterToApply.teaching = 0
        }
      }

      if (activeTab === RANKING_TAB.SURGEON && value === "All") {
        return
      }

      if (key === "bucket" && value === 4) {
        return
      }
      if (key === "specialty" || key === "bucket") {
        filterToApply[key] = value
      }
    })

    if (activeTab === RANKING_TAB.HOSPITAL) {
      if (isOverallHospital) {
        delete filterToApply.specialty
      }

      const filteredData = filter(hospitals, filterToApply)

      setFilteredHospitals(filteredData)
      const groupedByAlphabet = groupBy(filteredData, hospital =>
        hospital?.hospital?.charAt(0)
      )
      const keys = Object.keys(groupedByAlphabet)
      setAvailableLetters(keys)
    }

    if (activeTab === RANKING_TAB.SURGEON) {
      let groupedByAlphabet = []
      if (isEmpty(filterToApply)) {
        setFilteredSurgeons(surgeons)
        groupedByAlphabet = groupBy(surgeons, surgeon =>
          surgeon.last_name.charAt(0)
        )
      } else {
        if (!filterToApply.specialty) {
          filterToApply.bucket_all = filterToApply.bucket
        } else {
          if (filterToApply.bucket) {
            filterToApply.bucket_specialty = filterToApply.bucket
          }
        }
        delete filterToApply.bucket
        const filteredData = filter(surgeons, filterToApply)

        setFilteredSurgeons(filteredData)

        groupedByAlphabet = groupBy(filteredData, surgeon =>
          surgeon.last_name.charAt(0)
        )
        const keys = Object.keys(groupedByAlphabet)
        setAvailableLetters(keys)
      }
    }
  }, [activeFilters, activeTab, hospitals, surgeons, isOverallHospital])

  useEffect(() => {
    applyFilters()
  }, [applyFilters])

  return (
    <Box>
      {showPasswordForm ? (
        <PasswordForm setShowPasswordForm={setShowPasswordForm} />
      ) : (
        <Box sx={styles.container}>
          <Flex sx={styles.content}>
            <Box sx={styles.filter}>
              <RankingFilter
                onTabChange={handleTabchange}
                onFilterOptionChange={handleFilterOptionChange}
                activeFilters={activeFilters}
                activeTab={activeTab}
                hasSubspecialties={hasSubspecialties}
                isOverallHospital={isOverallHospital}
              />
            </Box>
            <Box sx={styles.table}>
              <RankingTable
                handleTabchange={handleTabchange}
                activeTab={activeTab}
                onFilterOptionChange={handleFilterOptionChange}
                hospitals={filteredHospitals}
                surgeons={filteredSurgeons}
                activeFilters={activeFilters}
                availableLetters={availableLetters}
                setShowAllData={setShowAllData}
                showAllData={showAllData}
                hasSubspecialties={hasSubspecialties}
                isOverallHospital={isOverallHospital}
              />
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

export default SectionBody

import React from "react"
import {
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Radio,
  RadioGroup,
  Stack,
  Icon,
  Text,
  Divider,
} from "@chakra-ui/react"
import { IoMdBusiness } from "react-icons/io"
import { HiOutlineUser } from "react-icons/hi"

import { RANKING_TAB, SUBSPECIALTIES } from "../../constants/constants"

const styles = {
  root: {
    color: "#2D3748",
    position: "sticky",
    top: "22px",
    bgColor: "white",
  },

  "ranking-header": {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21.48px",
    color: "#2D3748",
  },

  "tab-heading": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    py: "16px",
    outline: "1px solid #ECEFF7",
    px: "20px",
  },

  note: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#595C5F",
    mt: "8px",
  },

  "filter-title": {
    py: "12px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },

  "radio-item": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  icon: {
    h: "24px",
    w: "24px",
    pr: "4px",
  },
}

const RankingFilter = props => {
  const {
    onTabChange,
    onFilterOptionChange,
    activeFilters,
    activeTab,
    hasSubspecialties,
    isOverallHospital = false,
  } = props
  const handleClick = tab => {
    onTabChange(tab)
  }

  const renderTabs = () => {
    return (
      <Tabs
        variant="unstyled"
        isFitted
        index={activeTab === RANKING_TAB.SURGEON ? 0 : 1}
      >
        <TabList>
          <Tab
            _selected={{
              color: "#3399FF",
              outline: "1px solid #3399FF",
              fontWeight: 700,
              px: "17.5px",
            }}
            borderRadius="8px 0 0 8px"
            sx={styles["tab-heading"]}
            onClick={() => {
              handleClick(RANKING_TAB.SURGEON)
            }}
          >
            <Icon as={HiOutlineUser} sx={styles.icon} />
            Surgeons
          </Tab>
          <Tab
            _selected={{
              color: "#3399FF",
              outline: "1px solid #3399FF",
              fontWeight: 700,
              px: "17.5px",
            }}
            borderRadius="0 8px 8px 0"
            sx={styles["tab-heading"]}
            onClick={() => {
              handleClick(RANKING_TAB.HOSPITAL)
            }}
          >
            <Icon as={IoMdBusiness} sx={styles.icon} /> Hospitals
          </Tab>
        </TabList>
        <Box>
          {(hasSubspecialties || activeTab === RANKING_TAB.HOSPITAL) && (
            <Text sx={styles["ranking-header"]} mt="26px">
              Filter by
            </Text>
          )}
          {activeTab === RANKING_TAB.HOSPITAL && hasSubspecialties && (
            <Text sx={styles.note}>
              Please note that only one filter is applicable at a time.
            </Text>
          )}
        </Box>

        <TabPanels>
          <TabPanel p="0">
            {hasSubspecialties && (
              <>
                <Divider mt="13px" />
                <Text sx={styles["filter-title"]}>Subspecialities</Text>

                <RadioGroup
                  defaultValue={SUBSPECIALTIES.ALL}
                  onChange={value => {
                    onFilterOptionChange("specialty", value)
                  }}
                  value={activeFilters["surgeon"]?.["specialty"]}
                >
                  <Stack spacing={4} pt="8px">
                    <Radio size="lg" value={SUBSPECIALTIES.ALL}>
                      <Text sx={styles["radio-item"]}>General Orthopedics</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.HIP_AND_KNEE}>
                      <Text sx={styles["radio-item"]}>Hip & Knee</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.FOOT_AND_ANKLE}>
                      <Text sx={styles["radio-item"]}>Foot & Ankle</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.SHOULDER_AND_ELBOW}>
                      <Text sx={styles["radio-item"]}>Shoulder & Elbow</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.HAND_AND_WRIST}>
                      <Text sx={styles["radio-item"]}>Hand & Wrist</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}
          </TabPanel>
          <TabPanel p="0">
            <Divider mt="13px" />
            <Text sx={styles["filter-title"]}>Hospital Type</Text>

            <RadioGroup
              defaultValue="All"
              onChange={value => {
                onFilterOptionChange("hospitalType", value)
              }}
              value={activeFilters["hospital"]?.["hospitalType"]}
            >
              <Stack spacing="4" pt="8px">
                <Radio size="lg" value="All">
                  {" "}
                  <Text sx={styles["radio-item"]}>All Hospitals</Text>
                </Radio>
                <Radio size="lg" value="teaching" mt="12px">
                  <Text sx={styles["radio-item"]}>Teaching Hospitals</Text>
                </Radio>
                <Radio size="lg" value="community" mt="12px">
                  <Text sx={styles["radio-item"]}>Community Hospitals</Text>
                </Radio>
              </Stack>
            </RadioGroup>

            {hasSubspecialties && (
              <>
                <Divider mt="16px" />
                <Text sx={styles["filter-title"]}>Subspecialities</Text>

                <RadioGroup
                  defaultValue={SUBSPECIALTIES.ALL}
                  onChange={value => {
                    onFilterOptionChange("specialty", value)
                  }}
                  value={activeFilters["hospital"]?.["specialty"]}
                >
                  <Stack spacing={4} pt="8px">
                    <Radio size="lg" value={SUBSPECIALTIES.ALL}>
                      <Text sx={styles["radio-item"]}>General Orthopedics</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.HIP_AND_KNEE}>
                      <Text sx={styles["radio-item"]}>Hip & Knee</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.FOOT_AND_ANKLE}>
                      <Text sx={styles["radio-item"]}>Foot & Ankle</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.SHOULDER_AND_ELBOW}>
                      <Text sx={styles["radio-item"]}>Shoulder & Elbow</Text>
                    </Radio>
                    <Radio size="lg" value={SUBSPECIALTIES.HAND_AND_WRIST}>
                      <Text sx={styles["radio-item"]}>Hand & Wrist</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    )
  }

  const renderOverallHospitalFilter = () => {
    return (
      <>
        <Text sx={styles["ranking-header"]} mt="26px">
          Filter by
        </Text>
        <Divider mt="13px" />
        <Text sx={styles["filter-title"]}>Hospital Type</Text>

        <RadioGroup
          defaultValue="All"
          onChange={value => {
            onFilterOptionChange("hospitalType", value)
          }}
          value={activeFilters["hospital"]?.["hospitalType"]}
        >
          <Stack spacing="4" pt="8px">
            <Radio size="lg" value="All">
              {" "}
              <Text sx={styles["radio-item"]}>All Hospitals</Text>
            </Radio>
            <Radio size="lg" value="teaching" mt="12px">
              <Text sx={styles["radio-item"]}>Teaching Hospitals</Text>
            </Radio>
            <Radio size="lg" value="community" mt="12px">
              <Text sx={styles["radio-item"]}>Community Hospitals</Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </>
    )
  }

  return (
    <Box sx={styles.root}>
      <Box position="sticky">
        <Text sx={styles["ranking-header"]} mt="22px" mb="19px">
          See the All-Stars
        </Text>
      </Box>
      {isOverallHospital ? renderOverallHospitalFilter() : renderTabs()}
    </Box>
  )
}

export default RankingFilter

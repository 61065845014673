import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useDisclosure } from "@chakra-ui/react"

import { MdOutlineFilterAlt } from "react-icons/md"

import get from "lodash/get"
import groupBy from "lodash/groupBy"

import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  Text,
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Select,
} from "@chakra-ui/react"

import SurgeonList from "./surgeon-list"
import HospitalList from "./hospital-list"
import AlphabetDropdown from "./alphabet-dropdown"
import AlphabetSelector from "./alphabet-selector"

import {
  RANKING_TAB,
  DATA_LIMIT,
  DRAWER_POSITION,
  SUBSPECIALTIES,
  HOSPITAL_TYPE,
  PERCENTILE_BUCKET,
  PERCENTILE_TAB,
} from "../../constants/constants"
import ButtonSection from "./button-section"
import RankingFilter from "./ranking-filter"

const TAB_BREAKPOINT = 768
const MOBILE_HEADING_HEIGHT = 160
const DESKTOP_HEADING_HEIGHT = 180

const HOSPITAL_COLSPAN = 3
const SURGEON_COLSPAN = 4

const styles = {
  root: {
    color: "#2D3748",
  },
  "heading-wrapper": {
    flexDirection: { base: "column-reverse", md: "row", lg: "row" },
    justifyContent: "space-between",
    alignItems: { base: "flex-start", md: "center", lg: "center" },
    pt: { base: "0", md: "12px", lg: "12px" },
  },
  "table-heading": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  name: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
  },
  divider: {
    color: "#2D3748",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "4px",
    px: "16px",
    backgroundColor: "#ECEFF7",
  },
  "percentile-divider": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "4px",
    pl: "16px",
    backgroundColor: "#129459",
  },

  "column-heading": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "4px",
    px: "15px",
    color: "#2D3748",
    position: "sticky",
    top: 0,
  },
  "responsive-column-heading": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "0em",
    py: "4px",
    px: "15px",
    color: "#2D3748",
    display: { base: "none", md: "table-cell", lg: "table-cell" },
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    color: "#595C5F",
  },
  "tab-label": {
    display: { base: "none", md: "block", lg: "block" },
  },
  "filter-button": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    mr: { base: "0", md: "8px", lg: "8px" },
    px: "0",
    display: { base: "block", md: "block", lg: "none" },

    "&:hover,&:active,&:focus": {
      bg: "none",
      outline: "none",
      border: "none",
    },
  },
  "filter-label": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
  },
  "dropdown-option": {
    color: "#595C5F",
    fontSize: "16px",
    fontWeight: 400,
  },
}

const bucketToPercentileMap = {
  1: "TOP 1%",
  2: "TOP 2-3%",
  3: "TOP 4-5%",
}

const RankingTable = ({
  activeTab,
  onFilterOptionChange,
  activeFilters,
  hospitals = [],
  surgeons = [],
  handleTabchange,
  availableLetters,
  showAllData = false,
  hasSubspecialties = false,
  setShowAllData = () => {},
  isOverallHospital = false,
}) => {
  const data = useStaticQuery(
    graphql`
      query rankingLinkTemplateQuery {
        allContentfulSurgeonLink {
          nodes {
            npi
            href
          }
        }
        allContentfulProviderLink {
          nodes {
            href
            provider
          }
        }
      }
    `
  )
  const hospitalLinks = get(data, "allContentfulProviderLink.nodes")
  const surgeonLinks = get(data, "allContentfulSurgeonLink.nodes")

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [placement, setPlacement] = React.useState(DRAWER_POSITION.BOTTOM)

  let timeOutId = null

  const navigateToContactUs = () => window.open("/contact-us")

  const handleLoadAllAndScroll = letter => {
    if (!showAllData) {
      setShowAllData(true)
      timeOutId = setTimeout(() => {
        scrollToSection(letter)
        clearExistingTimeout()
      }, 100)
    } else {
      scrollToSection(letter)
    }
  }

  const clearExistingTimeout = () => {
    if (timeOutId) {
      clearTimeout(timeOutId)
      timeOutId = null
    }
  }
  const scrollToSection = letter => {
    const section = document.getElementById(letter)

    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        top: 0,
      })
    }
    if (section) {
      let topSpace =
        window.innerWidth < TAB_BREAKPOINT
          ? MOBILE_HEADING_HEIGHT
          : DESKTOP_HEADING_HEIGHT
      if (activeFilters[activeTab]?.bucket === PERCENTILE_TAB.FOURTH) {
        topSpace += 28
      }
      const offsetTop = section.getBoundingClientRect().top + window.scrollY
      const scrollToPosition = offsetTop - topSpace

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      })
    }
  }

  const renderGroupedItems = (groupedData, callback, rootKey = "") => {
    return Object.entries(groupedData)
      .filter(([key]) => key !== "null")
      .map(([key, value]) => {
        return (
          <React.Fragment key={`top-${key}`}>
            {callback(value, rootKey + key)}
          </React.Fragment>
        )
      })
  }

  const displayData = (
    data,
    callback,
    colspan = 3,
    groupByValue = "bucket"
  ) => {
    if (activeFilters[activeTab]?.bucket === PERCENTILE_TAB.FOURTH) {
      const groupedData =
        activeTab === RANKING_TAB.SURGEON
          ? groupBy(surgeons, groupByValue)
          : groupBy(hospitals, groupByValue)

      const result = Object.entries(groupedData)
        .filter(([key]) => key !== "null")
        .map(([key, value]) => {
          let groupedData = []

          if (activeTab === RANKING_TAB.SURGEON) {
            groupedData = groupBy(value, surgeon => surgeon.last_name.charAt(0))
          } else {
            groupedData = groupBy(value, hospital =>
              hospital?.hospital?.charAt(0)
            )
          }

          const keys = Object.keys(groupedData)

          return (
            <React.Fragment key={`top-${key}`}>
              <GridItem
                colSpan={colspan}
                position="sticky"
                top={{ base: "162px", md: "140px", lg: "140px" }}
                zIndex={1}
              >
                <Flex justifyContent="space-between">
                  <Box sx={styles["percentile-divider"]} width="100%">
                    <Text color="#FFFFFF">
                      {bucketToPercentileMap[key]} {activeTab.toUpperCase()}S
                    </Text>
                  </Box>

                  <Flex
                    display={{ base: "flex", md: "none", lg: "none" }}
                    backgroundColor="#129459"
                    alignItems="center"
                  >
                    <Box height="100%" width="2px" bg="white" mr="8px"></Box>
                    <AlphabetDropdown
                      availableAlphabets={keys}
                      onChange={val => {
                        scrollToSection(key + val)
                      }}
                      color="white"
                    />
                  </Flex>
                </Flex>
              </GridItem>

              <GridItem
                colSpan={colspan}
                position="sticky"
                zIndex={1}
                top="172px"
                display={{ base: "none", md: "block", lg: "block" }}
              >
                <Flex bg="white" p="12px">
                  <Text sx={styles["filter-label"]} pr="12px" color="#595C5F">
                    Jump to:
                  </Text>
                  <AlphabetSelector
                    availableLetters={keys}
                    onLetterSelect={val => scrollToSection(key + val)}
                  />
                </Flex>
              </GridItem>
              {renderGroupedItems(groupedData, callback, key)}
            </React.Fragment>
          )
        })

      return result
    } else {
      let groupedData = []

      if (activeTab === RANKING_TAB.SURGEON) {
        groupedData = groupBy(data, surgeon => surgeon.last_name.charAt(0))
      } else {
        groupedData = groupBy(data, hospital => hospital?.hospital?.charAt(0))
      }

      return renderGroupedItems(groupedData, callback)
    }
  }

  const displayHospitals = () => {
    const groupByValue =
      activeFilters.hospital.hospitalType !== HOSPITAL_TYPE.ALL
        ? PERCENTILE_BUCKET.hospital.HOSPITAL_TYPE
        : PERCENTILE_BUCKET.hospital.ALL

    return displayData(
      showAllData ? hospitals : hospitals.slice(0, DATA_LIMIT),
      renderHospitals,
      HOSPITAL_COLSPAN,
      groupByValue
    )
  }

  const renderHospitals = (hospitalList, key = 0) => {
    return (
      <HospitalList
        data={hospitalList}
        group={key}
        hospitalLinks={hospitalLinks}
      />
    )
  }

  const displaySurgeons = () => {
    const groupByValue =
      activeFilters.surgeon.subspecialty === SUBSPECIALTIES.ALL ||
      activeFilters.surgeon.specialty === SUBSPECIALTIES.ALL
        ? PERCENTILE_BUCKET.surgeon.ALL
        : PERCENTILE_BUCKET.surgeon.SUBSPECIALTY

    return displayData(
      showAllData ? surgeons : surgeons.slice(0, DATA_LIMIT),
      renderSurgeons,
      SURGEON_COLSPAN,
      groupByValue
    )
  }

  const renderSurgeons = (surgeonList, key) => {
    return (
      <SurgeonList
        data={surgeonList}
        group={key}
        surgeonLinks={surgeonLinks}
        hasSubspecialties={hasSubspecialties}
      />
    )
  }

  const handleFilterClick = () => {
    let drawerPlacement = DRAWER_POSITION.BOTTOM

    if (window.innerWidth >= TAB_BREAKPOINT) {
      drawerPlacement = DRAWER_POSITION.LEFT
    }

    setPlacement(drawerPlacement)
    onOpen()
  }

  return (
    <Box sx={styles.root}>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          height={{
            base: hasSubspecialties ? "750px" : "350px",
            md: "auto",
            lg: "auto",
          }}
        >
          <DrawerCloseButton zIndex={3} />
          <DrawerBody>
            <RankingFilter
              onTabChange={handleTabchange}
              onFilterOptionChange={onFilterOptionChange}
              activeFilters={activeFilters}
              activeTab={activeTab}
              hasSubspecialties={hasSubspecialties}
              isOverallHospital={isOverallHospital}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box position="sticky" top={"0px"} bg="white" zIndex={1}>
        <Flex sx={styles["heading-wrapper"]}>
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            width="100%"
            mt={{ base: "24px", md: "0", lg: "0" }}
          >
            <Box>
              <Heading sx={styles["table-heading"]} as="h2">
                Top{" "}
                {activeTab === RANKING_TAB.HOSPITAL ? "Hospitals" : "Surgeons"}
              </Heading>
              <Text display={{ base: "none", md: "block", lg: "block" }}>
                Sorted alphabetically within each category
              </Text>
            </Box>
            <Button
              sx={styles["filter-button"]}
              variant="ghost"
              leftIcon={<MdOutlineFilterAlt />}
              onClick={handleFilterClick}
            >
              Filters
            </Button>
          </Flex>

          <ButtonSection
            navigateToContactUs={navigateToContactUs}
            activeTab={activeTab}
          />
        </Flex>
      </Box>
      <Tabs
        variant="soft-rounded"
        colorScheme="green"
        pt="24px"
        pb="12px"
        index={activeFilters[activeTab]?.bucket - 1 || 0}
        position="sticky"
        top={{ base: "107px", md: "64px", lg: "64px" }}
        bg="white"
        zIndex={1}
        whiteSpace="nowrap"
        overflow="auto"
        display={{ base: "none", md: "block", lg: "block" }}
      >
        <TabList>
          <Tab
            onClick={() => {
              onFilterOptionChange("bucket", PERCENTILE_TAB.FIRST)
            }}
            bg="#ECEFF7"
            ml="3px"
          >
            <Text sx={styles["tab-label"]}>Top 1%</Text>
          </Tab>
          <Tab
            onClick={() => {
              onFilterOptionChange("bucket", PERCENTILE_TAB.SECOND)
            }}
            bg="#ECEFF7"
            ml="8px"
          >
            <Text sx={styles["tab-label"]}>Top 2-3%</Text>
          </Tab>
          <Tab
            onClick={() => {
              onFilterOptionChange("bucket", PERCENTILE_TAB.THIRD)
            }}
            bg="#ECEFF7"
            ml="8px"
          >
            <Text sx={styles["tab-label"]}>Top 4-5%</Text>
          </Tab>
          <Tab
            onClick={() => {
              onFilterOptionChange("bucket", PERCENTILE_TAB.FOURTH)
            }}
            bg="#ECEFF7"
            ml="8px"
          >
            All&nbsp;
            <Text sx={styles["tab-label"]}>
              Top{" "}
              {activeTab === RANKING_TAB.HOSPITAL ? "Hospitals" : "Surgeons"}
            </Text>
          </Tab>
        </TabList>
      </Tabs>
      {activeFilters[activeTab]?.bucket !== PERCENTILE_TAB.FOURTH && (
        <Flex
          position="sticky"
          top="140px"
          bg="white"
          p="12px"
          zIndex={1}
          display={{ base: "none", md: "flex", lg: "flex" }}
        >
          <Text sx={styles["filter-label"]} pr="12px">
            Jump to:
          </Text>
          <AlphabetSelector
            availableLetters={availableLetters}
            onLetterSelect={handleLoadAllAndScroll}
          />
        </Flex>
      )}

      <Flex
        position="sticky"
        top="114px"
        bg="white"
        zIndex={1}
        display={{ base: "flex", md: "none", lg: "none" }}
        pb="12px"
      >
        <Flex
          bg="#ECEFF7"
          py="6px"
          pl="18px"
          borderRadius="30px"
          alignItems="center"
        >
          <Text whiteSpace="nowrap" sx={styles["filter-label"]} color="#000000">
            Sort by:&nbsp;
          </Text>
          <Select
            variant="unstyled"
            value={activeFilters[activeTab]?.bucket}
            onChange={e => onFilterOptionChange("bucket", +e.target.value)}
            color="#000000"
            fontSize="14px"
            fontWeight={700}
          >
            <option value="1" style={styles["dropdown-option"]}>
              Top 1%
            </option>
            <option value="2" style={styles["dropdown-option"]}>
              Top 2-3%
            </option>
            <option value="3" style={styles["dropdown-option"]}>
              Top 4-5%
            </option>
            <option value="4" style={styles["dropdown-option"]}>
              All
            </option>
          </Select>
        </Flex>

        {activeFilters[activeTab]?.bucket !== PERCENTILE_TAB.FOURTH && (
          <Flex
            bg="#ECEFF7"
            py="6px"
            pl="18px"
            borderRadius="30px"
            ml="8px"
            alignItems="center"
          >
            <Text sx={styles["filter-label"]} color="#000000">
              Jump to:&nbsp;
            </Text>
            <AlphabetDropdown
              onChange={handleLoadAllAndScroll}
              availableAlphabets={availableLetters}
              color="#000000"
            />
          </Flex>
        )}
      </Flex>
      <Box minHeight="360px">
        <Grid
          templateColumns={`repeat(${
            activeTab === RANKING_TAB.HOSPITAL || !hasSubspecialties
              ? HOSPITAL_COLSPAN
              : SURGEON_COLSPAN
          })`}
          gridTemplateColumns={
            activeTab === RANKING_TAB.HOSPITAL
              ? "2fr 1fr 1fr"
              : hasSubspecialties
              ? "1fr 2fr 1fr 1fr"
              : "1fr 2fr 1fr"
          }
          columnGap={2}
        >
          <GridItem
            w="100%"
            h="7"
            whiteSpace="wrap"
            key="test"
            sx={styles["column-heading"]}
            colSpan={{ base: 2, md: 1, lg: 1 }}
          >
            {activeTab === RANKING_TAB.HOSPITAL ? "HOSPITAL" : "SURGEON"} NAME
          </GridItem>
          {/* {activeTab === RANKING_TAB.SURGEON && (
            <GridItem sx={styles["responsive-column-heading"]}>
              ORGANIZATION
            </GridItem>
          )} */}
          <GridItem w="100%" h="7" sx={styles["responsive-column-heading"]}>
            LOCATION
          </GridItem>
          {activeTab === RANKING_TAB.HOSPITAL && (
            <GridItem w="100%" h="7" sx={styles["responsive-column-heading"]}>
              {"TYPE"}
            </GridItem>
          )}

          {activeTab === RANKING_TAB.HOSPITAL
            ? displayHospitals()
            : displaySurgeons()}
        </Grid>
      </Box>
    </Box>
  )
}

export default RankingTable
